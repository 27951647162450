import './App.css';
import {LineChart} from "@mui/x-charts";
import {useState} from "react";
import {Button, Slider} from "@mui/material";

function App() {
    const [axis, setAxis] = useState({
        xAxis: { data: [1] },
        yAxis: { data: [1] }
    })

    const [sliderX, setSliderX] = useState(axis.xAxis.data.at(-1) + 1)
    const [sliderY, setSliderY] = useState(0)
    const [sliderFuncY, setSliderFuncY] = useState(1)
    const [sliderFuncX, setSliderFuncX] = useState(1)

    const sliderXValHandler = (e)=>{
        setSliderX(e.target.value)
    }
    const sliderYValHandler = (e)=>{
        setSliderY(e.target.value)
    }
    const sliderfuncXValHandler = (e)=>{
        setSliderFuncX(e.target.value)
        setFuncHandler(sliderFuncX, sliderFuncY)
    }
    const sliderfuncYValHandler = (e)=>{
        setSliderFuncY(e.target.value)
        setFuncHandler(sliderFuncX, sliderFuncY)
    }

    const addDotHandler = () =>{
        let newX = axis.xAxis.data
        let newY = axis.yAxis.data
        let formulaX= sliderX
        let formulaY= sliderY
        newX.push(formulaX)
        newY.push(formulaY)
        setAxis({
            xAxis: { data: newX },
            yAxis: { data: newY }
        })
        setSliderX(axis.xAxis.data.at(-1) + 1)
    }

    const setFuncHandler = (math_X, math_Y)=> {
        let iteration = 100
        let newX = []
        let newY = []
        let formulaX
        let formulaY
        for (let i = 1; i < iteration; i++){
            formulaX = i
            formulaY = Math.sin( i * math_X / math_Y )
            newX.push(formulaX)
            newY.push(formulaY)
        }
        setAxis({
            xAxis: { data: newX },
            yAxis: { data: newY }
        })
        setSliderX(formulaX + 1)
    }

    const popDotHandler = () =>{
        let newX = axis.xAxis.data
        let newY = axis.yAxis.data
        newX.pop()
        newY.pop()
        setAxis({
            xAxis: { data: newX },
            yAxis: { data: newY }
        })
        setSliderX(axis.xAxis.data.at(-1) + 1)
    }

    const popAllDotHandler = () =>{
        setAxis({
            xAxis: { data: [1] },
            yAxis: { data: [1] }
        })
        setSliderX(2)
    }


    return (
        <div className="App">
            <div className={'ChartBox'}>
                <Slider
                    onChange={sliderYValHandler}
                    orientation="vertical"
                    aria-label="Small"
                    valueLabelDisplay="on"
                    marks
                    defaultValue={0}
                    step={0.1}
                    min={-1}
                    max={1}
                />

                <LineChart
                    xAxis={[
                        axis.xAxis
                    ]}
                    series={[
                        axis.yAxis
                    ]}
                    width={600}
                    height={400}
                />
            </div>


            <div className={'slider'}>
                <Slider
                    value={sliderX}
                    onChange={sliderXValHandler}
                    aria-label="Small"
                    valueLabelDisplay="on"
                    min={axis.xAxis.data.at(-1) + 1}
                    max={axis.xAxis.data.at(-1) + 11}
                    marks
                />
            </div>
            <div>
                <Button on onClick={addDotHandler} style={{marginRight: '10px'}} variant="contained">add</Button>
                <Button style={{marginRight: '10px'}} disabled={axis.xAxis.data.length === 1} onClick={popDotHandler} variant="contained" color="error">delete</Button>
                <Button style={{marginRight: '10px'}} disabled={axis.xAxis.data.length === 1} onClick={popAllDotHandler} variant="contained" color="error">delete all</Button>
                <Button onClick={ () => {setFuncHandler(1, 10)}} variant="contained" color="success">let func</Button>
            </div>
            <hr style={{width: "100%"}}/>
            <div className={'slider_two'}>
                <Slider
                    value={sliderFuncX}
                    onChange={sliderfuncXValHandler}
                    aria-label="Small"
                    valueLabelDisplay="default"
                    min={1}
                    max={50}
                    step={0.5}
                    marks
                />
                <Slider
                    value={sliderFuncY}
                    onChange={sliderfuncYValHandler}
                    aria-label="Small"
                    valueLabelDisplay="default"
                    min={1}
                    max={50}
                    step={0.5}
                    marks
                />

            </div>
        </div>
    );
}

export default App;
